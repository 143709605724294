import './App.css';
import { Router, Link } from "@reach/router";
import Routes from './containers/Routes';

function App() {


  
  return (
    <div className="App">
      <header>

      </header>
      
      <main>
        <Routes />
      </main>
    </div>
  );
}

export default App;
